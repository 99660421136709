<template functional>
  <div class="certificates">
    <div class="certificates__item">
      <img
        v-lazy="require('@/assets/modern/ssl.svg')"
        alt="ssl"
      >
    </div>
    <div class="certificates__item">
      <img
        v-lazy="require('@/assets/modern/sha.svg')"
        alt="sha"
      >
    </div>
    <div class="certificates__item">
      <img
        v-lazy="require('@/assets/modern/soc.svg')"
        alt="soc"
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="scss" scoped>
.certificates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 12px;
  width: 100%;
  margin-top: auto;

  &__item {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    margin-top: 64px;
    background-color: $white-gray;
    border-radius: 10px;
    width: 100%;
  }
}
</style>
